<template>
    <v-dialog v-model="dialog" persistent max-width="500px">
        <v-card>
            <v-card-title>Clear user application settings</v-card-title>
            <v-card-text>
                <p>
                    This will stop
                    <b>all applications</b>
                    and clear global user application settings. This can help to reset certain applications (e.g. Rstudio) to a clean state. Please note that
                    this operation can affect
                    <b>all of your applications</b>
                    .
                </p>
                <p>A backup snapshot will be created to be able to revert this operation if needed.</p>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="dialog = false">Cancel</v-btn>
                <v-btn color="primary" @click="reset">Clear settings</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data() {
        return {
            dialog: false,
            aid: '0'
        }
    },
    methods: {
        reset() {
            this.$axios
                .post(`/applications/${this.aid}/reset_session`)
                .then(r => {
                    this.dialog = false
                    // clear application restart message
                    this.$store.commit('setUserMetadata', { last_app_start: { ...this.$route.params, aid: 0, start_time: Date.now() } })
                    if (r.data.tkid) {
                        this.$store.dispatch('showGlobalDialog', {
                            dialogTitle: 'Reset user application settings',
                            dialogText: 'Stopping applications and resetting user application settings. Please wait, this can take a few minutes.',
                            dialogAction: ['dismiss'],
                            taskId: r.data.tkid
                        })
                    }
                })
                .catch(e => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'An error has occured, please try again later.',
                        snackBarIcon: 'error'
                    })
                })
        }
    },
    watch: {
        $route: {
            handler: function (to) {
                if (to.query && to.query.resetAid) {
                    this.dialog = true
                    this.$router.replace({ query: {} })
                    this.aid = to.query.resetAid.toString()
                }
            },
            immediate: true
        }
    }
}
</script>
